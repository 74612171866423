export const theme = {
  // Brand
  omniumLightBlue: '#c2deea',
  omniumDarkBlue: '#165788',
  omniumRed: '#f7403a',
  omniumDark: '#3b4044',
  omniumLightGrey: '#bbbbbb',
  omniumGrey: '#c2cacd',

  // these are from the brand guidelines, wlil need to sync up on the greys lol
  omniumGreyDark: '#536A71',
  omniumGreyLight: '#D1D4D3',


  chartLightGrey: '#D0CECE',
  chartDarkGrey: '#AFABAB',
  chartLightRed: '#FFB0B7',
  chartDarkRed: '#FF8691', 
  chartLightBlue: '#A2CDDF',
  chartDarkBlue: '#72B3CF',
}