import React from 'react';

import SecondModuleView from 'views/SecondModuleView';

export default () => {
  // This is where we'll make requests to get the actual data
  const placeholderData = [
    {
      label: 'Dollar Sales LY',
      value: 167823677.9
    },
    { label: 'TDP',
      value: 6008249.561
    },
    { label: 'Incremental Volume',
      value: -3841829.784	
    },
    { label: 'Base Velocity - Price',
      value: 1306892.485	
    },
    { label: 'Base Velocity - Media',
      value: 516969.1824	
    },
    { label: 'Base Velocity - COVID',
      value: -21888206.87	
    },
    { label: 'Base Velocity - Unexplained',
      value: -6167282.483	
    },
    { label: 'Dollars TY',
      value: 143758470
    },
  ];

  return <SecondModuleView chart1Data={placeholderData} />;
}