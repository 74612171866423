import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  ComposedChart,
  Line,
  Bar,
  ResponsiveContainer,
  YAxis,
  CartesianGrid,
  Label,
} from 'recharts';


import { theme } from 'components/styles/variables';
import Formatted, { toRounded, toDollars } from 'components/Formatted';

const LEFT_CHART_MARGIN = 200;
const RIGHT_CHART_MARGIN = 80;
const Y_AXIS_WIDTH = 80;

const GlobalStyles = createGlobalStyle`
  // Need this for centerinog y axis labels
  // see: https://github.com/recharts/recharts/issues/2105

  .profit-and-demand__y-axis-text {
    text-anchor: middle;
  }
`

const DataTableContainer = styled.div`
  //  We need the 1% because width is 99% on the responsive container :(
  padding-right: calc(${RIGHT_CHART_MARGIN + Y_AXIS_WIDTH}px + 1%);
`

const DataTableGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfColumns }) => `${LEFT_CHART_MARGIN + Y_AXIS_WIDTH}px repeat(${numberOfColumns}, 1fr)`};
`

const DataTableColumn = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`

const DataTableCell = styled.div`
  border-top: 1px solid ${theme.omniumLightGrey};
  border-left:  1px solid ${theme.omniumLightGrey};
  &:last-child {
    border-bottom: 1px solid ${theme.omniumLightGrey};
  }

  ${({ isLast }) => isLast ? `border-right: 1px solid ${theme.omniumLightGrey};` : ''}

  display: flex;
  justify-content: center;

  padding: 6px 8px;
`

const BlankCell = styled.div`
  padding: 6px 8px;
`

const LegendDataTableCell = styled(DataTableCell)`
  padding-left: 12px;
  justify-content: flex-start;
`;

const LegendBox = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: solid ${({ borderColor }) => borderColor};
  border-width: ${({ isThin }) => isThin ? '5px' : '2px'};
  width: 40px;
  height: 16px;
  margin-right: 4px;
`

const Title = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
`

const SupplyChainProfitTable = ({ data }) => {
  const columns = data.map(({ everyDayPriceDollars, supplyChainProfit, demandIndex, baselineDistribution }, index) => {
    const isLast = index === data.length - 1;
    
    return <DataTableColumn key={`data-table-${index}`}>
      <DataTableCell isLast={isLast}>
        <Formatted value={everyDayPriceDollars} type='dollars' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={supplyChainProfit} type='dollars' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={demandIndex} type='rounded' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={baselineDistribution} type='percentage'/>
      </DataTableCell>
    </DataTableColumn>
  })

  return (
    <DataTableGrid numberOfColumns={columns.length}>
      <DataTableColumn>
      <BlankCell></BlankCell>
        <LegendDataTableCell>
        <LegendBox backgroundColor={theme.chartLightGrey} borderColor={theme.chartDarkGrey}/>
          <span>Supply Chain Profit</span>
        </LegendDataTableCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor={theme.omniumDarkBlue} borderColor='white' isThin={true}/>
          <span>Demand Index</span>
        </LegendDataTableCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor='white' borderColor='white'/>
          <span>Baseline Distribution</span>
        </LegendDataTableCell>
      </DataTableColumn>
      {columns}
    </DataTableGrid>
  )
}

const ProfitBreakoutTable = ({ data, manufacturerName }) => {
  const columns = data.map(({ everyDayPriceDollars, retailProfit, manufacturerProfit, demandIndex, baselineDistribution, netUnitCostDollars }, index) => {
    const isLast = index === data.length - 1;
    
    return <DataTableColumn key={`data-table-${index}`}>
      <DataTableCell isLast={isLast}>
        <Formatted value={everyDayPriceDollars} type='dollars' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={retailProfit} type='dollars' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={manufacturerProfit} type='dollars' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={netUnitCostDollars} type='dollars' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={demandIndex} type='rounded' fixed={2}/>
      </DataTableCell>
      <DataTableCell isLast={isLast}>
        <Formatted value={baselineDistribution} type='percentage'/>
      </DataTableCell>
    </DataTableColumn>
  })

  return (
    <DataTableGrid numberOfColumns={columns.length}>
      <DataTableColumn>
        <BlankCell></BlankCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor={theme.chartLightRed} borderColor={theme.chartDarkRed}/>
          <span>Retail Profit</span>
        </LegendDataTableCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor={theme.chartLightBlue} borderColor={theme.chartDarkBlue}/>
          <span>{manufacturerName} Profit</span>
        </LegendDataTableCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor='white' borderColor='white'/>
          <span>Net Unit Cost</span>
        </LegendDataTableCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor={theme.omniumDarkBlue} borderColor='white' isThin={true}/>
          <span>Demand Index</span>
        </LegendDataTableCell>
        <LegendDataTableCell>
          <LegendBox backgroundColor='white' borderColor='white'/>
          <span>Baseline Distribution</span>
        </LegendDataTableCell>
      </DataTableColumn>
      {columns}
    </DataTableGrid>
  )
}

const DataTable = ({ data, manufacturerName, chartMode }) => {
  switch (chartMode) {
    case 'ProfitBreakout':
      return <ProfitBreakoutTable data={data} manufacturerName={manufacturerName}/>
    case 'SupplyChainProfit':
      return <SupplyChainProfitTable data={data} manufacturerName={manufacturerName}/>
    default:
      throw new Error(`${chartMode}: unknown chart mode!`)
  }
}

const ChartContainer = styled.div`
  background-color: white;
`

// TODO
// fix tick count
// add param and financial labels

// chart mode can be either 'ProfitBreakout' or 'SupplyChainProfit'
const PricingChart = ({ data, manufacturerName, productFinancials, chartMode, productGroupName }) => {
  const { cogs, retailMarginPercentage } = productFinancials;
  const retailMargin = retailMarginPercentage / 100;
  
  const calculatedData = data.map(({ everyDayPriceDollars, baselineDistribution, demandIndex }) => {
    const netUnitCostDollars = everyDayPriceDollars * (1 - retailMargin);
    const supplyChainProfit = demandIndex * (everyDayPriceDollars - cogs);
    const retailProfit = everyDayPriceDollars * demandIndex * retailMargin;
    const manufacturerProfit = demandIndex * ( netUnitCostDollars - cogs);
    
    return {
      everyDayPriceDollars,
      baselineDistribution,
      demandIndex,
      netUnitCostDollars,
      supplyChainProfit,
      retailProfit,
      manufacturerProfit,
    }
  });

  const barWidth = calculatedData.length >= 4 ? 40 : 75;

  const showProfitBreakout = chartMode === 'ProfitBreakout';
  const title =  showProfitBreakout ? `Profit Breakout for ${manufacturerName}/${productGroupName}` : `Supply Chain Profit for ${manufacturerName}/${productGroupName}`;


  return (
    <ChartContainer>
      <GlobalStyles /> 
      <Title>{title}</Title>
      {/* setting width to 99 cause of issue here: https://github.com/recharts/recharts/issues/1423 theres likely a better fix, but this worked quickly*/}
      <ResponsiveContainer width="99%" height={500}>
        <ComposedChart
          data={calculatedData}
          margin={{
            top: 20,
            right: RIGHT_CHART_MARGIN,
            bottom: 0,
            left: LEFT_CHART_MARGIN,
        }}
          stackOffset='sign'
        >
          <CartesianGrid stroke={theme.omniumGreyLight} vertical={false}  />
          <YAxis yAxisId='left' width={Y_AXIS_WIDTH} tickFormatter={(value) => toDollars(value, 2)} strokeWidth={0}>
            <Label className='profit-and-demand__y-axis-text' position='insideLeft' angle={-90} textAnchor='middle'>Dollars</Label>
          </YAxis>
          <YAxis yAxisId='right' width={Y_AXIS_WIDTH} orientation='right' tickFormatter={(value) => toRounded(value, 2)} strokeWidth={0} >
            <Label className='profit-and-demand__y-axis-text' position='insideRight' angle={-90} textAnchor='middle'>Demand Index (Unit Sales)</Label>
          </YAxis>
            <Bar
              isAnimationActive={false}
              yAxisId='left' 
              stackId='a' 
              dataKey={showProfitBreakout ? 'retailProfit' : 'supplyChainProfit'} 
              barSize={barWidth} 
              fill={showProfitBreakout ? theme.chartLightRed : theme.chartLightGrey}
              stroke={showProfitBreakout ? theme.chartDarkRed : theme.chartDarkGrey} 
              strokeWidth={2}/>
            {showProfitBreakout && <Bar yAxisId='left' stackId='a' dataKey='manufacturerProfit' barSize={barWidth} fill={theme.chartLightBlue} stroke={theme.chartDarkBlue} strokeWidth={2} isAnimationActive={false} />}
          <Line yAxisId="right" type="linear" dataKey="demandIndex" stroke={theme.omniumDarkBlue} dot={false} strokeWidth={4} isAnimationActive={false}/>
        </ComposedChart>

      </ResponsiveContainer>
      <DataTableContainer>
        <DataTable manufacturerName={manufacturerName} data={calculatedData} chartMode={chartMode}/>
      </DataTableContainer>
    </ChartContainer>
  );
}

export default PricingChart;