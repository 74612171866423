import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';
import { sortBy } from 'lodash';

import { ModuleContainer, Module, ModuleCard, ModuleControls } from 'components/module';
import PricingChart from 'components/charts/Pricing';
import WithNavbar from 'components/WithNavbar';
import { theme } from 'components/styles/variables';
import PricingSimulator from 'components/simulators/Pricing';
import Formatted from 'components/Formatted';

const StyledSelect = styled(ReactSelect)`
  width: 400px;
  margin-right: 40px;
  border-color: ${theme.omniumLightGrey};
  color: ${theme.omniumDark};
`

// TODO create mixin and move this to input file
const StyledNumberInput = styled(NumberFormat)`
  padding: ${({ size }) => size === 'small' ? '8px' : '16px'};
  color: ${theme.omniumDark};
  outline: none;
  border: 1px solid ${theme.omniumLightGrey};
  border-radius: 4px;
  &:focus {
    outline: none;
  }
`

const isValidPercent = (inpObj) => {
  const { value } = inpObj;
  
  if (value >= 0 && value <= 100) {
    return inpObj;
  }
}

const InputControl = styled.div`
  display: grid;
  grid-template-columns: 126px 60px;
`

const RetailLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;  
`

const ChartAndSimulatorSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  
  @media screen and (min-width: 1500px) {
    grid-template-columns: 1fr 450px;
  }
`

const SimulatorCard = styled(ModuleCard)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1500px) {
    display: block;
  }
`

const ChartModeSelectorTab = styled.div`  
  padding: 6px 8px;
  cursor: pointer;
  text-align: center;

  &:hover {
    border-radius 4px;
    background-color: ${theme.chartLightBlue};
    color: white;
  }

  ${({ isActive }) => isActive && `
    border-radius 4px;
    background-color: ${theme.chartLightBlue};
    color: white;
  `}  
`

const ChartModeSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2px;
  border-radius: 4px;
  padding: 2px; 
  margin-right: 40px;
  border: 1px solid ${theme.omniumLightGrey};
`

const ChartModeSelector = ({ handleUpdateChartMode, currentChartMode }) => {
  return (
    <ChartModeSelectorContainer>
      
      <ChartModeSelectorTab 
        onClick={() => handleUpdateChartMode('SupplyChainProfit')}
        isActive={currentChartMode === 'SupplyChainProfit'}
      >
          Supply Chain Profit
      </ChartModeSelectorTab>
      <ChartModeSelectorTab 
        onClick={() => handleUpdateChartMode('ProfitBreakout')}
        isActive={currentChartMode === 'ProfitBreakout'}
      >
        Profit Breakout
      </ChartModeSelectorTab>
    </ChartModeSelectorContainer>
  )
};

const ElasticityContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 32px;
  font-weight: bold;
`

const Elasticity = ({ pricingData }) => {
  const sortedData = sortBy(pricingData, ['everydayPriceDollars']);

  const smallest = sortedData[0];
  const largest = sortedData[sortedData.length - 1];

  const elasticity = Math.log(largest.demandIndex/smallest.demandIndex) / Math.log(largest.everyDayPriceDollars/smallest.everyDayPriceDollars)

  return (
    <ElasticityContainer>
      Average Elasticity: <Formatted value={elasticity} type='rounded' fixed={2}/>
    </ElasticityContainer>
  )
}

export default ({ 
  pricingModelRun,
  manufacturerName,
  showPricingMatrix,
}) => {
  const [currentChartMode, setCurrentChartMode] = useState('SupplyChainProfit');
  const [retailMarginPercentage, setRetailMarginPercentage] = useState(30);
  
  const { productGroupData } = pricingModelRun;


  // if there is totalDollarSales in productFinancials, then we sort product group names
  // by that value. otherwise, we just return the list of product group names in their
  // original order.
  // NOTE: this assumes that product group names will be unique
  let productGroupNames;
  if (productGroupData && 'totalDollarSales' in productGroupData[0].productFinancials) {
    productGroupNames = productGroupData.sort((a, b) => {
      return b.productFinancials.totalDollarSales -  a.productFinancials.totalDollarSales;
   }).map(( { productGroupName }) => productGroupName);
  } else {
    productGroupNames = productGroupData.map(({ productGroupName }) => productGroupName);
  }

  const [currentProductGroupName, setCurrentProductGroupName] = useState(productGroupNames[0]);

  const productGroupNameOptions = productGroupNames.map(productGroupName => {
    return {
      value: productGroupName,
      label: productGroupName,
    }
  })

  const currentProductGroup = useMemo(() => {
    return productGroupData.find(productGroup => productGroup.productGroupName === currentProductGroupName);
  }, [currentProductGroupName, productGroupData]);

  const currentProductGroupData = useMemo(() => {
    return currentProductGroup.data
  }, [currentProductGroup]);

  const [currentEDP, setCurrentEDP] = useState(currentProductGroupData[0].everyDayPriceDollars);
  const [proposedEDP, setProposedEDP] = useState(currentProductGroupData[1].everyDayPriceDollars);

  const handleUpdateCurrentProductGroupName = (groupName) => {
    setCurrentProductGroupName(groupName);
    // Note: not sure why i have to do this instead of referrint to currentProductGroupData, but the timing isn't working
    const newProductGroupData = productGroupData.find(productGroup => productGroup.productGroupName === groupName).data
    const currentEDP = newProductGroupData[0].everyDayPriceDollars
    const proposedEDP = newProductGroupData[1].everyDayPriceDollars
    setCurrentEDP(currentEDP)
    setProposedEDP(proposedEDP);
  }

  return (
    <WithNavbar>
      <ModuleContainer>
        <Module>
          <h1>Pricing</h1>

          <ModuleControls>
            <StyledSelect
              value={{ value: currentProductGroupName, label: currentProductGroupName}} 
              options={productGroupNameOptions}
              onChange={(e) => { handleUpdateCurrentProductGroupName(e.value)}}
              />

            <ChartModeSelector handleUpdateChartMode={setCurrentChartMode} currentChartMode={currentChartMode}/>
            
            {currentChartMode === 'ProfitBreakout' && 
              <InputControl>
                <RetailLabel htmlFor='pricing-module__retail-margin-input'><span>Retail Margin</span></RetailLabel>
                <StyledNumberInput id='pricing-module__retail-margin-input' size='small' suffix='%' value={retailMarginPercentage} onValueChange={({ value }) => setRetailMarginPercentage(value) } isAllowed={isValidPercent} decimalScale={0}/>
              </InputControl>
            }
          </ModuleControls>

          <ChartAndSimulatorSection>
            <ModuleCard>
              <PricingChart data={currentProductGroupData} productFinancials={{ ...currentProductGroup.productFinancials, retailMarginPercentage: retailMarginPercentage }} manufacturerName={manufacturerName} chartMode={currentChartMode} productGroupName={currentProductGroupName}/>
              <Elasticity pricingData={currentProductGroupData} />
            </ModuleCard>
            <SimulatorCard>
              <PricingSimulator 
                data={currentProductGroupData} 
                productGroupInfo={currentProductGroup.productFinancials} 
                currentEDP={currentEDP}
                proposedEDP={proposedEDP}
                handleUpdateCurrentEDP={setCurrentEDP}
                handleUpdateProposedEDP={setProposedEDP}/>
            </SimulatorCard>
          </ChartAndSimulatorSection>
          {showPricingMatrix && <div>Pricing Matrix</div>}
        </Module>
      </ModuleContainer>
    </WithNavbar>
  ) 
}
