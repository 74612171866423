import React, { useContext } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { theme } from 'components/styles/variables';
import { auth } from 'firebaseConfig';
import { UserContext } from 'providers/User';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  height: 100vh;
`

const NavBar = styled.div`
  width: 100%;
  padding: 40px 20px;
`

const NavLink = styled(Link)`
  width: 100%;
  display: block;
  text-decoration: none;
  color: ${theme.omniumDarkBlue}
`

const NavHeading = styled.div`
  font-size: 24px;
  background-color: white;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;
`

const OmniTitle = styled.div`
  padding: 16px;
  text-align: center;
`

const NavActions = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 100%;
  padding: 40px 20px;
  // height of heading - padding top - padding bottom - heading margin
  height: calc(100vh - 75px - 40px - 40px - 40px);

  display: grid;
  grid-template-rows: 1fr 20px;
`

const SignOut = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const ModulesSection = styled.section`

`

const SectionHeader = styled.h3`
  margin-top: 0;
`


export default ({ children }) => {
  const { signOut } = useContext(UserContext);

  return (
    <Grid>
      <NavBar>
        <NavHeading>
          <OmniTitle>OmniCloud</OmniTitle>
          <BrandBar />
        </NavHeading>

        <NavActions>
          <ModulesSection>
            <SectionHeader>Modules</SectionHeader>
            <NavLink to='/pricing'>Pricing</NavLink>
          </ModulesSection>
          
          <SignOut onClick={signOut}>Sign Out</SignOut>
        </NavActions>    
      </NavBar>

      {children}
    </Grid>
  )
}

const BrandBarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 16px;
  border-radius: 8px;
`
const BrandBarRed = styled.div`
  background-color: ${theme.omniumRed};
  border-bottom-right-radius: 8px;
`
const BrandBarDarkBlue = styled.div`
  background-color: ${theme.omniumDarkBlue};
`
const BrandBarLightBlue = styled.div`
  background-color: ${theme.omniumLightBlue};
  border-bottom-left-radius: 8px;
`

const BrandBar = () => {
  return (
    <BrandBarContainer>
      <BrandBarLightBlue/>
      <BrandBarDarkBlue/>
      <BrandBarRed/>
    </BrandBarContainer>
  )
}