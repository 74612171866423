import styled from 'styled-components';
import { theme } from 'components/styles/variables';

export const ModuleContainer = styled.div`
  padding: 40px 20px;
`

export const Module = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
`


export const ModuleCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const ModuleCard = styled.div`
  padding: 40px 0;
  margin-bottom: 16px;
`

export const ModuleControls = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${theme.omniumGreyLight};
  padding: 36px 0px 16px 0px
`