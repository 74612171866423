import { createGlobalStyle, withTheme } from 'styled-components';

// I this this automatically adds itself?
import Normalize from 'normalize.css';

import { theme } from 'components/styles/variables';


export default createGlobalStyle`
  * {
    box-sizing: border-box; 
  }

  body {
    font-family: 'Montserrat', sans-serif;
    color: ${theme.omniumDark};
    background-color: #DDEBF7;
  }
`;
