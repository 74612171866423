import React, { useMemo, useState } from 'react'
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import Formatted, { toDollars } from 'components/Formatted';
import { theme } from 'components/styles/variables';


const ROW_HEADER_WIDTH = 210;
const ROW_VALUE_WIDTH = 110;
const COLUMN_GAP = 16;
const DEFAULT_NUM_ROWS = 3;

const EPDSelect = styled(ReactSelect)`
  width: 100px;
`

const SimulatorGrid = styled.div`
  display: grid;
  grid-template-columns: ${(ROW_HEADER_WIDTH)}px ${(ROW_VALUE_WIDTH)}px ${(ROW_VALUE_WIDTH)}px;
  column-gap: ${(COLUMN_GAP)}px;
`

const SimulatorRow = styled.div`
 
`

const SimulatorColumn = styled.div`
  display:grid;
  grid-template-rows: repeat(${({ numRows }) => numRows}, 1fr)

`;

const SimulatorNumberCell = styled.div`
  text-align: right;
  margin: 8px;
`;

const SimulatorTextCell = styled.div`
  text-align: left;
  margin: 10px;
`;

const IndentedSimulatorTextCell = styled.div`
  text-algin: left;
  margin: 10px;
  padding-left: 16px;
`

const StyledSimulatorBreak = styled.hr`
  width: ${(ROW_HEADER_WIDTH) + 2*(ROW_VALUE_WIDTH + COLUMN_GAP)}px;
  margin: 8px;
`;

const StyledNumberInput = styled(NumberFormat)`
  width: 100px;
  padding: ${({ size }) => size === 'small' ? '8px' : '16px'};
  border: 1px solid ${theme.omniumLightGrey};
  border-radius: 4px;
  outline: none;
  text-align: right;

  &:focus {
    outline: none;
  }
`

const Title = styled.div`
  text-align: left;
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 24px;
`

const isValidPercent = (inpObj) => {
  const { value } = inpObj;
  
  if (value >= 0 && value <= 100) {
    return inpObj;
  }
}


const PricingSimulator = ({ data, productGroupInfo, currentEDP, proposedEDP, handleUpdateCurrentEDP, handleUpdateProposedEDP }) => {
  const allEDPS = useMemo(() => data.map(d => d.everyDayPriceDollars), data);
  
  const { listPrice, cogs } = productGroupInfo;

  const edpOptions = allEDPS.map(edp => { 
    return {
      value: edp,
      label: toDollars(edp)
    }
  })

  const currentDemandIndex = useMemo(() => {
    return data.find(d => d.everyDayPriceDollars === currentEDP).demandIndex
  }, [currentEDP, data])

  const [currentRetailMarginPercentage, setCurrentRetailMarginPercentage] = useState(30);
  const currentRetailMargin = useMemo(() => {
    return currentRetailMarginPercentage/100; 
  }, [currentRetailMarginPercentage]);

  const [currentBaseUnitsPerWeek, setCurrentBaseUnitsPerWeek] = useState(1000);
  
  const currentRetailDollarsPerWeek = currentBaseUnitsPerWeek * currentEDP;
  const currentRetailProfitPerWeek = currentRetailDollarsPerWeek * currentRetailMargin;
  const currentListSalesPerWeek = currentBaseUnitsPerWeek * listPrice;
  const currentNetSalePerWeek = currentEDP * (1 - currentRetailMargin) * currentBaseUnitsPerWeek;
  const currentProfitPerWeek = currentNetSalePerWeek - (cogs * currentBaseUnitsPerWeek)
  const currentTradeSpendPerWeek =  currentListSalesPerWeek - currentNetSalePerWeek;
  const currentTradeSpendToSales = currentTradeSpendPerWeek / currentListSalesPerWeek;
  
  const proposedDemandIndex = useMemo(() => {
    return data.find(d => d.everyDayPriceDollars === proposedEDP).demandIndex
  }, [proposedEDP, data])

  const [proposedRetailMarginPercentage, setProposedRetailMarginPercentage] = useState(30);
  const proposedRetailMargin = useMemo(() => {
    return proposedRetailMarginPercentage/100; 
  }, [proposedRetailMarginPercentage]);
  
  const proposedBaseUnitsPerWeek = currentBaseUnitsPerWeek * (proposedDemandIndex/currentDemandIndex);
  const baseChange = proposedBaseUnitsPerWeek / currentBaseUnitsPerWeek - 1

  const proposedRetailDollarsPerWeek = proposedBaseUnitsPerWeek * proposedEDP;
  const proposedRetailProfitPerWeek = proposedRetailDollarsPerWeek * proposedRetailMargin;
  const retailChange = proposedRetailProfitPerWeek / currentRetailProfitPerWeek - 1;

  const proposedListSalesPerWeek = proposedBaseUnitsPerWeek * listPrice;
  const proposedNetSalePerWeek = proposedEDP * (1 - proposedRetailMargin) * proposedBaseUnitsPerWeek;
  const proposedProfitPerWeek = proposedNetSalePerWeek - (cogs * proposedBaseUnitsPerWeek)
  const profitChange = proposedProfitPerWeek / currentProfitPerWeek - 1

  const proposedTradeSpendPerWeek =  proposedListSalesPerWeek - proposedNetSalePerWeek;
  const proposedTradeSpendToSales = proposedTradeSpendPerWeek / proposedListSalesPerWeek;
  const grossRevenueEfficiency = (proposedListSalesPerWeek - currentListSalesPerWeek) / (proposedTradeSpendPerWeek - currentTradeSpendPerWeek)


  return (
    <>
  <Title>Everyday Price Change P&L Simulation</Title>  
  <SimulatorGrid>
    <SimulatorColumn numRows={3}>
      <SimulatorTextCell>Everyday Price</SimulatorTextCell>
      <SimulatorTextCell>Retail Margin</SimulatorTextCell>
      <SimulatorTextCell>Demand Index</SimulatorTextCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={3}>
      <SimulatorNumberCell>
        <EPDSelect value={{value: currentEDP, label: toDollars(currentEDP)}} options={edpOptions} onChange={({value}) => handleUpdateCurrentEDP(value)}/>
      </SimulatorNumberCell>
      <SimulatorNumberCell><StyledNumberInput size='small' suffix='%' value={currentRetailMarginPercentage} onValueChange={({ value }) => setCurrentRetailMarginPercentage(value) } isAllowed={isValidPercent} decimalScale={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={currentDemandIndex} type='rounded' fixed={2}/></SimulatorNumberCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={3}>
      <SimulatorNumberCell><EPDSelect value={{value: proposedEDP, label: toDollars(proposedEDP)}} options={edpOptions} onChange={({value}) => handleUpdateProposedEDP(value)}/></SimulatorNumberCell>
      <SimulatorNumberCell><StyledNumberInput size='small' suffix='%' value={proposedRetailMarginPercentage} onValueChange={({ value }) => setProposedRetailMarginPercentage(value) } isAllowed={isValidPercent} decimalScale={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={proposedDemandIndex} type='rounded' fixed={2}/></SimulatorNumberCell>
    </SimulatorColumn>
  </SimulatorGrid>
  <StyledSimulatorBreak />
  <SimulatorGrid>
    <SimulatorColumn numRows={2}>
      <SimulatorTextCell>Base Units / Week</SimulatorTextCell>
      <IndentedSimulatorTextCell>% Change vs. Current</IndentedSimulatorTextCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={2}>
      <SimulatorNumberCell><StyledNumberInput size='small' allowNegative={false} value={currentBaseUnitsPerWeek} onValueChange={({ value }) => setCurrentBaseUnitsPerWeek(value) } decimalScale={0} thousandSeparator={true}/></SimulatorNumberCell>
      <SimulatorNumberCell></SimulatorNumberCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={2}>
      <SimulatorNumberCell><Formatted value={proposedBaseUnitsPerWeek} type='rounded'/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={baseChange} type='percentage' fixed={1}/></SimulatorNumberCell>
    </SimulatorColumn>
  </SimulatorGrid>
  <StyledSimulatorBreak />
  <SimulatorGrid>
    <SimulatorColumn numRows={3}>
      <SimulatorTextCell>Retail Dollars / Week</SimulatorTextCell>
      <SimulatorTextCell>Retail Profit / Week</SimulatorTextCell>
      <IndentedSimulatorTextCell>% Change vs. Current</IndentedSimulatorTextCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={3}>
      <SimulatorNumberCell><Formatted value={currentRetailDollarsPerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={currentRetailProfitPerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell></SimulatorNumberCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={3}>
      <SimulatorNumberCell><Formatted value={proposedRetailDollarsPerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={proposedRetailProfitPerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={retailChange} type='percentage' fixed={1}/></SimulatorNumberCell>
    </SimulatorColumn>
  </SimulatorGrid>
  <StyledSimulatorBreak />
  <SimulatorGrid>
    <SimulatorColumn numRows={4}>
      <SimulatorTextCell>List Sales / Week</SimulatorTextCell>
      <SimulatorTextCell>Net Sales / Week</SimulatorTextCell>
      <SimulatorTextCell>Profit / Week</SimulatorTextCell>
      <IndentedSimulatorTextCell>% Change vs. Current</IndentedSimulatorTextCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={4}>
      <SimulatorNumberCell><Formatted value={currentListSalesPerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={currentNetSalePerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={currentProfitPerWeek} type='dollars' fixed={0} /></SimulatorNumberCell>
      <SimulatorNumberCell></SimulatorNumberCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={4}>
      <SimulatorNumberCell><Formatted value={proposedListSalesPerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={proposedNetSalePerWeek} type='dollars' fixed={0}/></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={proposedProfitPerWeek} type='dollars' fixed={0} /></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={profitChange} type='percentage' fixed={1}/></SimulatorNumberCell>
    </SimulatorColumn>
  </SimulatorGrid>
  <StyledSimulatorBreak />
  <SimulatorGrid>
    <SimulatorColumn numRows={2}>
      <SimulatorTextCell>Trade Spend / Week</SimulatorTextCell>
      <SimulatorTextCell>Gross Revenue Efficiency</SimulatorTextCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={2}>
      <SimulatorNumberCell><Formatted value={currentTradeSpendPerWeek} type='dollars' fixed={0} /></SimulatorNumberCell>
      <SimulatorNumberCell></SimulatorNumberCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={2}>
      <SimulatorNumberCell><Formatted value={proposedTradeSpendPerWeek} type='dollars' fixed={0} /></SimulatorNumberCell>
      <SimulatorNumberCell><Formatted value={grossRevenueEfficiency} type='rounded' fixed={2}/></SimulatorNumberCell>
    </SimulatorColumn>
  </SimulatorGrid>
  <StyledSimulatorBreak />
  <SimulatorGrid>
    <SimulatorColumn numRows={1}>
      <SimulatorTextCell>T:S</SimulatorTextCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={1}>
      <SimulatorNumberCell><Formatted value={currentTradeSpendToSales} type='percentage' fixed={1}/></SimulatorNumberCell>
    </SimulatorColumn>
    <SimulatorColumn numRows={1}>
      <SimulatorNumberCell><Formatted value={proposedTradeSpendToSales} type='percentage' fixed={1} /></SimulatorNumberCell>
    </SimulatorColumn>
  </SimulatorGrid>
  </>
  );
}

export default PricingSimulator;
