
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDekP35eAR3_faY6V-BmxVValUGzh3FgjA",
  authDomain: "omnicloud-503e4.firebaseapp.com",
  databaseURL: "https://omnicloud-503e4-default-rtdb.firebaseio.com",
  projectId: "omnicloud-503e4",
  storageBucket: "omnicloud-503e4.appspot.com",
  messagingSenderId: "861143336612",
  appId: "1:861143336612:web:ceaf2aa8951282ca428831",
  measurementId: "G-S8VWHN93RN"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();