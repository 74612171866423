import React, { useState } from 'react';
import styled from 'styled-components';

import { theme } from 'components/styles/variables';
import Input from 'components/Input';
import Button from 'components/Button';

const LoginBackground = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 2fr;
  position: relative;
  background: linear-gradient(90deg, ${theme.omniumDarkBlue} 50%, ${theme.omniumLightBlue} 50%);
`

const LoginBackgroundTop = styled.div`
  background-color: ${theme.omniumLightBlue};
  border-bottom-left-radius: 25%;
  
`
const LoginBackgroundBottom = styled.div`
  background-color: ${theme.omniumDarkBlue};
  border-top-right-radius: 25%;
`

const LoginForm = styled.form`
  width: 400px;
  height: 480px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%);
  position: absolute;
  top: calc(40% - 240px);
  left: calc(50% - 200px);

  padding: 32px;
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  margin-bottom: 4px;
  ${({ isInvalid }) => { if (isInvalid) {
    return `color: ${theme.omniumRed}`
  }}}
`

const Heading = styled.h1`
  margin-bottom: 32px;
`

const InvalidMessage = styled.p`
  color: ${theme.omniumRed};
`

export default ({ handleLogin, handleUpdateEmail, handleUpdatePassword, email, password, formIsInvalid }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  }

  
  return (
    <LoginBackground>
      <LoginBackgroundTop />
      <LoginBackgroundBottom />
      <LoginForm onSubmit={handleSubmit}>
        <Heading>OmniCloud</Heading>
     
        <Label htmlFor='email' isInvalid={formIsInvalid}>Email</Label>
        <Input id='email' value={email} onChange={e => handleUpdateEmail(e.target.value)} isInvalid={formIsInvalid} size='large'/>
        <Label htmlFor='password' isInvalid={formIsInvalid}>Password</Label>
        <Input id='password' type='password' value={password} onChange={e => handleUpdatePassword(e.target.value)} isInvalid={formIsInvalid} size='large'/>
        {formIsInvalid && 
          <InvalidMessage>Please enter a valid email and password</InvalidMessage>
        } 
        <Button>Sign in</Button>
      </LoginForm>
    </LoginBackground>
  )
}