import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { UserContext } from 'providers/User';

// Following https://reactrouter.com/native/example/auth-workflow
const AuthenticatedRoute = ({ component: Component, path, ...rest }) => {
  const userContext = useContext(UserContext);
  
  return (
    <Route 
      {...rest}
      render={props =>
        userContext.user ? (
          <Component path={path} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
};


export default AuthenticatedRoute;