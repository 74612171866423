import styled from 'styled-components';

import { theme } from 'components/styles/variables';

const Button = styled.button`
 border: none;
 background-color: ${theme.omniumDarkBlue};
 color: white;
 padding: 16px;
 border-radius: 4px;
 margin-top: 16px;
`

export default Button;