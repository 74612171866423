import React from 'react';

import { ModuleContainer, ModuleCard } from 'components/module';
import WaterfallChart from 'components/charts/WaterfallChart';

import WithNavbar from 'components/WithNavbar';

export default ({ chart1Data, chart2Data }) => {
  return (
    <WithNavbar>
      <ModuleContainer>
        <h1>Second Module</h1>

        <ModuleCard>
          <WaterfallChart data={chart1Data}/>
        </ModuleCard>
      </ModuleContainer>
    </WithNavbar>
  )
}