import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import LoginView from 'views/LoginView';
import { auth } from 'firebaseConfig';
import { UserContext } from 'providers/User';

export default ({ location }) => {
  const userContext = useContext(UserContext)
  const { from } = location.state || { from: { pathname: "/pricing" } };    
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formIsInvalid, setFormIsInvalid] = useState(false);

  // TODO Validate
  const login = async () => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      userContext.updateUser(user)  
    } catch (error) {
      // TYPES: do something with these
      //  email: auth/invalid-email
      setFormIsInvalid(true) 
    }
  }

  if (userContext.user) {
    return <Redirect to={from} />
  } else {
    return <LoginView 
      email={email} 
      password={password} 
      formIsInvalid={formIsInvalid}
      handleLogin={login}
      handleUpdateEmail={setEmail} 
      handleUpdatePassword={setPassword}/>
  }
}