
import styled from 'styled-components';

import { theme } from 'components/styles/variables';

const Input = styled.input`
 padding: ${({ size }) => size === 'small' ? '8px' : '16px'};
 border: 1px solid ${theme.omniumLightGrey};
 border-radius: 4px;
 margin-bottom: 32px;
 outline: none;

 &:focus {
   outline: none;
 }

 ${({ isInvalid }) => {
   if (isInvalid) {
     return `border-color: ${theme.omniumRed}`
   }
 }}
`

export default Input;