import React, { useEffect, useState, useContext } from 'react';
import PricingModuleView from 'views/PricingModuleView';

import { firestore } from 'firebaseConfig';
import { UserContext } from 'providers/User';

const fetchData = async (userId, updatePricingModelRun, updateManufacturerName, updateIsLoading) => {  
  const manufacturer = firestore.collection("manufacturers").where("users", "array-contains", userId);
  
  manufacturer.get().then((querySnapShot) => {
    // _should_ only be one here
    querySnapShot.forEach((doc) => {
      const data = doc.data();
      updatePricingModelRun(data.modelData.find(modelRun => modelRun.name === 'pricing'));
      updateManufacturerName(data.name);
      updateIsLoading(false);
    });
  })
  .catch((error) => {
    console.log(error);
    updateIsLoading(false)
  })
}

export default () => {
  const [pricingModelRun, setPricingModelRun] = useState({});
  const [manufacturerName, setManufacturerName] = useState({});
  
  const [isLoading, setIsLoading] = useState(true)
  const { user, featureFlags } = useContext(UserContext);

  useEffect(() => { 
    fetchData(user.uid, setPricingModelRun, setManufacturerName, setIsLoading);
  }, [user])

  if (isLoading) {
    return <div>{/* TODO add a loading state */}</div>
  }

  return <PricingModuleView 
    pricingModelRun={pricingModelRun}
    manufacturerName={manufacturerName}
    showPricingMatrix={!!featureFlags.showPricingMatrix}
     />;
}