import React from 'react';

/* 
 * Not generally a fan of regex like this, but it's source in these two sites,
 * and seems to work well:
 * https://www.codegrepper.com/code-examples/javascript/add+commas+to+number+javascript
 * https://blog.abelotech.com/posts/number-currency-formatting-javascript/
 */
export const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const toDollars = (value, fixed=2) => {
  return `$${formatNumberWithCommas(value.toFixed(fixed))}`;
}

export const toPercentage = (value, fixed=2) => {
  return `${formatNumberWithCommas((value * 100).toFixed(fixed))}%`;
}

export const toRounded = (value, fixed=2) => {
  return formatNumberWithCommas(value.toFixed(fixed));
}


export default ({ value, type, fixed=0 }) => {
  switch (type) {
    case 'percentage':
      return <span>{toPercentage(value, fixed)}</span>
    case 'dollars':
      return <span>{toDollars(value, fixed)}</span>
    case 'rounded':
      return <span>{toRounded(value, fixed)}</span>
    default:
      return <span>{value}</span>
  }  
}