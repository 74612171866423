import React from 'react';

import { Cell, BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid } from 'recharts';

const rangeData = [
  { day: '05-01', temperature: [-1, 10] },
  { day: '05-02', temperature: [2, 15] },
  { day: '05-03', temperature: [3, 12] },
  { day: '05-04', temperature: [4, 12] },
  { day: '05-05', temperature: [12, 16] },
  { day: '05-06', temperature: [5, 16] },
  { day: '05-07', temperature: [3, 12] },
  { day: '05-08', temperature: [0, 8] },
  { day: '05-09', temperature: [-3, 5] },
];

const WaterfallChart = ({ data }) => {
  // Going to assume the two totals are first and last elements for now
  const startTotal = data[0];
  const endTotal = data[data.length - 1];
  const drivers = data.slice(1, -1);

  const waterfallData = data.reduce((previousData, currentEntry, index) => {
    if (index === 0 || index === data.length -1) {
      return [...previousData, {
        ...currentEntry,
        value: currentEntry.value,
        chartValues: [0, currentEntry.value],
      }
    ]
    } else { 
      const previousEntry = previousData[index - 1];
      const currentValue = currentEntry.value;

      const { chartValues: previousChartValues, value: previousValue } = previousEntry;

      let chartValues = [];
      if (currentValue > 0) {
        if (previousValue > 0) {
          chartValues = [previousChartValues[1], previousChartValues[1] + currentValue] 
        } else {
          chartValues = [previousChartValues[0], previousChartValues[1] + currentValue] 
        }
      } else {
        if (previousValue > 0) {
          chartValues = [previousChartValues[1] + currentValue, previousChartValues[1]] 
        } else {
          chartValues = [previousChartValues[0], previousChartValues[0] + currentValue] 
        }
      }
      return [
        ...previousData,
        {
        ...currentEntry,
        originalValue: currentEntry.value,
        chartValues
      }]
    }
  }, []);

  return (
  <div>
  <BarChart
      width={730}
      height={250}
      data={waterfallData}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <XAxis dataKey="day" />
      <YAxis />
      <CartesianGrid vertical={false} />
      <Tooltip />
      <Bar dataKey="chartValues" fill="#8884d8" >
        {data.map((entry, index) => {
          let fill = 'grey';
          if (index !== 0 && index !== data.length - 1) {
            if (entry.value > 0) {
              fill = 'steelblue';
            } else {
              fill = 'tomato';
            }
          }
        
          return <Cell fill={fill} key={`cell-${index}`} />
        })}
      </Bar>
    </BarChart>
  </div>
  )

}

export default WaterfallChart;