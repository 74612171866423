import React, { createContext, useEffect, useState, useMemo } from 'react';
import QueryString from 'query-string';

import { auth } from 'firebaseConfig';

function getFeatureFlags() {
  return QueryString.parse(window.location.search, { parseBooleans: true });
}

export const UserContext = createContext({ 
  user: null,
  userIsLoading: true,
  updateUser: () => {},
  signOut: () => {},
  featureFlags: {},
 });

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userIsLoading, setUserIsLoading] = useState(true);

  const signOut = async () => {
    await auth.signOut();

    setUser(null);
  }
  
  useEffect(() => {
    auth.onAuthStateChanged(authUser => {
      setUser(authUser);
      setUserIsLoading(false);
    })
  }, []);

  // Feature flags will likely come from the user model so adding them here now
  const featureFlags = useMemo(() => getFeatureFlags(), []);

  return <UserContext.Provider value={{ user, userIsLoading, updateUser: setUser, signOut, featureFlags }}>
    {!userIsLoading  && children}
  </UserContext.Provider>
}

export default UserProvider;