import React from 'react';

import Routes from 'components/Routes';
import GlobalStyles from 'components/styles/GlobalStyles';
import UserProvider from 'providers/User';

const Application = () => {
 return (
  <>
    <UserProvider>
      <Routes />
    </UserProvider>
    <GlobalStyles />
  </>
 )
}

export default Application;