import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import PricingModuleRoute from 'routes/PricingModuleRoute';
import SecondModuleRoute from 'routes/SecondModuleRoute';
import LoginRoute from 'routes/LoginRoute';
import AuthenticatedRoute from 'components/AuthenticatedRoute';

export default () => {
  return (
    <Router>
        <Switch>          
          {/* AUTHENTICATE THIS */}
          <AuthenticatedRoute path='/pricing' component={PricingModuleRoute} />
          <AuthenticatedRoute path='/second' component={SecondModuleRoute} />
          
          
          <Route path='/login' component={LoginRoute}/>
          <Route path='/' component={LoginRoute} />
        </Switch> 
    </Router>
  );
}